<template>
  <div class="ProcessTwo">
    <div v-for="(i, k) in values" :key="k" class="pmContainer">
      <div :data="`i${k}`" class="pmL"></div>
      <div class="pmL-outer">
        <div class="pmName" :style="{maxWidth: i && i.ratio? 3.1 * i.ratio: 0}">{{ i.name }}</div>
        <Progress
            id="ssss"
            :stroke-color="color || {
              from: '#0AAAD8',
              to: '#0274EE',
              direction: 90
            }"
            :percent="i && i.ratio"
            :show-info="false"
            :stroke-width="window.screen.width === 1280? 33: 50"
            trail-color="transparent"
            status="normal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {Progress} from "ant-design-vue";
import 'ant-design-vue/lib/progress/style/index.css'
export default {
  name: "ProcessTwo",
  components: {Progress},
  props: {
    values: {
      required: false,
      default: () => [],
    },
    color: {
      required: false,
      default: 'linear-gradient(270deg, #F67265 2%, #FFE375 100%)',
    }
  },
  data() {
    return {
      window: window,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.ProcessTwo {
  width: 100%;
  height: 100%;
  padding: 12px 32px;

  .pmL-outer {
    flex: 1;
    margin-left: -108px;
    position: relative;
    @media (max-width: 1280px) {
      margin-left: -68px;
    }
  }

  .pmContainer {
    display: flex;
    align-items: center;
  }
  .pmContainer + .pmContainer {
    margin-top: 20px;
    @media(max-width: 1280px){
      margin-top: 15px;
    }
  }

  .pmL {
    width: 54px;
    height: 54px;
    @include backgroundMix;
    position: relative;
    z-index: 10;
    @media(max-width: 1280px){
      width: 35px;
      height: 35px;
    }
  }
  .pmL[data="i0"] {
    background-image: url("../../assets/images/rd/11.png");
  }

  .pmL[data="i1"] {
    background-image: url("../../assets/images/rd/22.png");
  }

  .pmL[data="i2"] {
    background-image: url("../../assets/images/rd/33.png");
  }

  .pmL[data="i3"] {
    background-image: url("../../assets/images/rd/44.png");
  }

  .pmL[data="i4"] {
    background-image: url("../../assets/images/rd/55.png");
  }

  .pmName {
    font-size: 20px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 110px;
    transform: translateY(-50%);
    //max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media(max-width: 1280px){
      font-size: 13px;
      left: 68px;
    }
  }
  #ssss{
    width: 394px;
    @media (max-width: 1280px) {
      width: 263px;
    }
  }
}
</style>
