<template>
  <div class="YiBiaoPanOne">
    <div v-for="(i, k) in data" :key="k" class="panItem">
      <div style="text-align: center">
        <div class="panName">{{nameFunc(k)}}</div>
        <div class="panNum">{{i}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YiBiaoPanOne",
  props: {
    data: {
      required: false,
      type: Array,
    }
  },
  setup(){
    const nameFunc = (k) => {
      switch (k) {
        case 0:
          return '课题总数';
        case 1:
          return '课件总数';
        case 2:
          return '课后练习总数';
        default:
          return '知识点总数';
      }
    }
    return {
      nameFunc,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.YiBiaoPanOne {
  display: flex;
  align-items: center;
  .panItem{
    width: 182px;
    height: 182px;
    background-image: url("../../assets/images/rd/组 4059@2x.png");
    @include backgroundMix;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 1280px){
      width: 121px;
      height: 121px;
    }
  }
  .panItem+ .panItem{
    margin-left: 25px;
    @media(max-width: 1280px){
      margin-left: 17px;
    }
  }
  .panName{
    font-size: 18px;
    font-family: Source Han Sans CN;
    color: #28F7FF;
    @media(max-width: 1280px){
      font-size: 12px;
    }
  }

  .panNum{
    font-size: 30px;
    font-weight: bold;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    @media(max-width: 1280px){
      font-size: 20px;
    }
  }
}
</style>
