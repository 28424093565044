<template>
  <div class="ProcessOne">
    <div v-for="(i, k) in values" :key="k" class="poContainer">
      <div :data="`i${k}`" class="poL"/>
      <div style="flex: 1; margin-left: -16px; text-align: left">
        <div class="poName">{{ i.name }}</div>
        <Progress
            id="ssss"
            :stroke-color="color || {
              from: '#32CEEE',
              to: '#0F86EB',
              direction: 277
          }"
            :percent="i && i.ratio"
            :show-info="false"
            :stroke-width="15"
            trail-color="transparent"
            status="normal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {Progress} from "ant-design-vue";
import 'ant-design-vue/lib/progress/style/index.css'

export default {
  name: "ProcessOne",
  components: {Progress},
  props: {
    values: {
      required: false,
      default: () => [],
    },
    color: {
      required: false,
      default: 'linear-gradient(270deg, #F67265 2%, #FFE375 100%)',
    }
  },
  setup() {
    let progressEl = ref(null);

    onMounted(() => {

    })

    return {
      progressEl,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";

.ProcessOne {
  width: 100%;
  height: 100%;
  padding: 32px;

  .poContainer {
    display: flex;
    align-items: center;
  }

  .poContainer + .poContainer {
    margin-top: 18px;
    @media(max-width: 1280px) {
      margin-top: 9px;
    }
  }

  .poL {
    width: 52px;
    height: 50px;
    @include backgroundMix;
    position: relative;
    z-index: 20;
    @media(max-width: 1280px) {
      width: 34px;
      height: 33px;
      transform: translateY(3px);
    }
  }

  .poL[data='i0'] {
    background-image: url("../../assets/images/rd/1.png");
  }

  .poL[data='i1'] {
    background-image: url("../../assets/images/rd/2.png");
  }

  .poL[data='i2'] {
    background-image: url("../../assets/images/rd/3.png");
  }

  .poL[data='i3'] {
    background-image: url("../../assets/images/rd/4.png");
  }

  .poL[data='i4'] {
    background-image: url("../../assets/images/rd/5.png");
  }

  .poName {
    font-size: 15px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    text-align: left;
    padding-left: 20px;
    @media(max-width: 1280px) {
      font-size: 12px;
      padding-left: 13px;
      margin-bottom: -4px;
    }
  }
  #ssss{
    width: 385px;
    @media (max-width: 1280px) {
      width: 257px;
    }
  }
}
</style>
