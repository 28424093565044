<template>
  <div class="RDCenter">
    <page-info :page-title="rdTitle"/>
    <div class="content">
      <div class="left">
        <div class="box">
          <opacity-card-title :title="`PPT课件受欢迎的课题排行（${nowMonth}月）`" :width="sWidth"/>
          <process-one :values="pptcoursewareList" color="linear-gradient(270deg, #F67265 2%, #FFE375 100%)"/>
        </div>
        <div class="box">
          <opacity-card-title :title="`作品小样受欢迎的课题排行（${nowMonth}月）`" :width="sWidth"/>
          <process-two :values="workSamplesList" color="linear-gradient(90deg, #7257BF 0%, #3E3BA9 99%)"/>
        </div>
      </div>
      <div class="middle">
        <div class="midTop">
          <yi-biao-pan-one :data="[topicNum, coursewareNum, topicWorkNum, knowledgeAnimationNum]"/>
        </div>
        <div class="midBottom">
          <opacity-card-title title="课题整体受欢迎排行" width="335"/>
          <div class="midBottom-content" v-if="topicPopularList && topicPopularList.length > 0">
            <div class="mbTop3">
              <div class="mb1">{{topicPopularList.length > 0 && topicPopularList[0].name}}</div>
              <div class="mb2">{{ topicPopularList.length > 1 && topicPopularList[1].name}}</div>
              <div class="mb3">{{ topicPopularList.length > 2 && topicPopularList[2].name}}</div>
            </div>

          </div>
          <div class="mbAfter3">
            <div v-for="(i, k) in topL">{{i.name}}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="box">
          <opacity-card-title :title="`教学动画受欢迎的课题排行（${nowMonth}月）`" :width="sWidth"/>
          <process-one :values="teachingAnimationList" color="linear-gradient(277deg, #B350FF 0%, #433AC2 100%)"/>
        </div>
        <div class="box">
          <opacity-card-title :title="`教材受欢迎的课题排行（${nowMonth}月）`" :width="sWidth"/>
          <process-two :values="teachingMaterialList" color="linear-gradient(270deg, #02668E 0%, #41A7AC 100%)'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageInfo from "../components/PageInfo";
import OpacityCardTitle from "../components/OpacityCardTitle";
import moment from "moment";
import ProcessOne from "../components/RDCenter/ProcessOne";
import {onMounted, onUnmounted, reactive, toRefs} from "vue";
import service from "../utils/axios";
import ProcessTwo from "../components/RDCenter/ProcessTwo";
import YiBiaoPanOne from "../components/RDCenter/YiBiaoPanOne";
import {changePage} from "../utils/getPageTime";
import {useRouter} from "vue-router";

export default {
  name: "RDCenter",
  components: {YiBiaoPanOne, ProcessTwo, ProcessOne, OpacityCardTitle, PageInfo},
  data() {
    return {
      rdTitle: require('/src/assets/images/rd/rd_title.png'),
      sWidth: window.screen.width === 1280 ? 227 : 330,
      nowMonth: moment().format('M'),
    }
  },
  computed:{
    topL(){
      let topL = JSON.parse(JSON.stringify(this.topicPopularList));
      topL.length > 3 && topL.splice(0, 3)
      return topL
    }
  },
  setup() {
    let router = useRouter();
    let state = reactive({
      pptcoursewareList: [],
      teachingAnimationList: [],
      workSamplesList: [],
      teachingMaterialList: [],
      topicNum: 0, //课题数
      coursewareNum: 0,  //课件数
      knowledgeAnimationNum: 0, //知识点动画数
      topicWorkNum: 0, //课后练习数,
      topicPopularList: [],
      timeout: null,
    })

    const getData = async () => {
      const data = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/topicResearch')
      const data2 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/research')
      state.pptcoursewareList = data.pptcoursewareList || [];
      state.teachingAnimationList = data.teachingAnimationList || [];
      state.workSamplesList = data.workSamplesList || [];
      state.teachingMaterialList = data.teachingMaterialList || []
      state.topicNum = data2.topicNum || 0
      state.coursewareNum = data2.coursewareNum || 0
      state.knowledgeAnimationNum = data2.knowledgeAnimationNum || 0
      state.topicWorkNum = data2.topicWorkNum || 0
      state.topicPopularList = data2.topicPopularList || []
      state.timeout = await changePage(router, '课题研发');
    }

    onMounted(() => {
      getData();
    })

    onUnmounted(()=>{
      state.timeout && clearTimeout(state.timeout)
    })

    return {
      ...toRefs(state),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";

.RDCenter {
  @include backgroundMix;

  .content {
    display: flex;
    padding: 45px;
    @media(max-width: 1280px) {
      padding: 30px;
    }
  }

  .left {
    width: 478px;
    margin-right: 35px;
    @media(max-width: 1280px) {
      width: 319px;
      margin-right: 23px;
    }
  }

  .middle {
    flex: 1;
    @media(max-width: 1280px) {
      width: 530px;
    }
  }

  .right {
    width: 478px;
    margin-left: 35px;
    @media(max-width: 1280px) {
      width: 319px;
      margin-right: 23px;
    }
  }

  .box {
    height: 430px;
    @include backgroundMix;
    background-image: url("../assets/images/rd/boxBack1.png");
    text-align: center;
    @media(max-width: 1280px) {
      height: 289px;
    }
  }

  .box + .box {
    margin-top: 27px;
    @media(max-width: 1280px) {
      margin-top: 18px;
    }
  }

  .midBottom-content{
    padding: 0 45px;
    @media (max-width: 1280px) {
      padding: 0 33px;
    }
  }

  .midBottom {
    height: 676px;
    @include backgroundMix;
    background-image: url("../assets/images/rd/bt_box.png");
    text-align: center;
    @media(max-width: 1280px){
      height: 457px;
    }
  }

  .midTop {
    height: 182px;
    margin-bottom: 25px;
    @media(max-width: 1280px){
      height: 121px;
      margin-bottom: 17px;
    }
  }

  .mbTop3 {
    @include backgroundMix;
    background-image: url("../assets/images/rd/pic_zhuozi@2x.png");
    display: inline-block;
    width: 699px;
    height: 172px;
    margin-top: 20px;
    font-size: 20px;
    position: relative;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    @media(max-width: 1280px){
      width: 465px;
      height: 115px;
      margin-top: 15px;
      font-size: 13px;
    }
  }
  .mb1{
    position: absolute;
    top: 83px;
    left: 50%;
    transform: translateX(-55%);
    width: 180px;
    text-align: center;
    @media(max-width: 1280px){
      top: 55px;
      width: 120px;
    }
  }

  .mb2{
    position: absolute;
    top: 112px;
    left: 25px;
    width: 180px;
    text-align: center;
    @media(max-width: 1280px){
      top: 75px;
      width: 120px;
      left: 17px;
    }
  }

  .mb3{
    position: absolute;
    bottom: 8px;
    right: 23px;
    width: 200px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 1280px){
      bottom: 8px;
      right: 20px;
      width: 120px;
      height: 31px;
    }
  }

  .mbAfter3{
    padding-top: 5px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    @media(max-width: 1280px){
      padding-top: 3px;
      font-size: 13px;
    }
    div{
      padding: 13px 0;
      @media(max-width: 1280px){
        padding: 9px 0;
      }
    }
    :nth-child(2n + 1){
      background: linear-gradient(6deg, #00558E, #031E7A);
    }
    :nth-child(2n){
      background: linear-gradient(90deg, #0A80B5 0%, #0446A8 100%);
    }
  }

  .right {
    width: 478px;
    margin-left: 35px;
    @media(max-width: 1280px){
      width: 319px;
      margin-right: 23px;
    }
  }
}
</style>
